

export default function Tokenomics() {
  return (
    <>
      <div
        className="box tokenomics"
        data-aos="zoom-in-up"
        data-aos-duration="900"
        data-aos-offset="-200"
      >
        <span className="c_gray small">Total Supply</span>
        <strong>10,000,000,000 GNW</strong>
      </div>
      <div
        className="box tokenomics"
        data-aos="zoom-in-up"
        data-aos-duration="900"
      >
        <span className="c_gray small">Token Sale</span>
        <strong>15%</strong>
      </div>
      <div
        className="box tokenomics"
        data-aos="zoom-in-up"
        data-aos-duration="900"
      >
        <span className="c_gray small">Operation</span>
        <strong>5%</strong>
      </div>
      <div
        className="box tokenomics"
        data-aos="zoom-in-up"
        data-aos-duration="900"
      >
        <span className="c_gray small">Development</span>
        <strong>10%</strong>
      </div>
      <div
        className="box tokenomics"
        data-aos="zoom-in-up"
        data-aos-duration="900"
      >
        <span className="c_gray small">Marketing</span>
        <strong>10%</strong>
      </div>
      <div
        className="box tokenomics"
        data-aos="zoom-in-up"
        data-aos-duration="900"
        data-aos-offset="-200"
      >
        <span className="c_gray small">Market Liquidity</span>
        <strong>25%</strong>
      </div>
      <div
        className="box tokenomics"
        data-aos="zoom-in-up"
        data-aos-duration="900"
        data-aos-offset="-200"
      >
        <span className="c_gray small">Ecosystem</span>
        <strong>30%</strong>
      </div>
      <div
        className="box tokenomics"
        data-aos="zoom-in-up"
        data-aos-duration="900"
        data-aos-offset="-200"
      >
        <span className="c_gray small">Partners</span>
        <strong>5%</strong>
      </div>
    </>
  )
}